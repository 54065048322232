<template>
  <div>
    <a-drawer
        :title="title"
        :width="1000"
        :visible="drawerVisible"
        :body-style="{ paddingBottom: '0' }"
        @close="onCloseDrawer"
    >
      <div class="search-box">
        <a-form-model class="panel panel-default panel-search" :model="form" layout="inline">
          <a-col flex="auto">
            <div>经销商名称：{{ form.dealerName || "-" }}</div>
            <div>标签生效周期：{{ form.effectStartTime }} 至 {{ form.effectEndTime }}</div>
          </a-col>
          <a-col class="tools">
            <a-form-model-item label=" " :colon="false">
              <a-input
                  v-model="labelName"
                  placeholder="请输入三级标签名称"
              />
            </a-form-model-item>
            <a-space>
              <a-button @click="handleReset">重置</a-button>
              <a-button type="primary" @click="handleSearch">查询</a-button>
            </a-space>
          </a-col>
        </a-form-model>
      </div>
      <div class="content-box">
        <div class="tab-box">
          <a
              v-for="(item,index) of form.list"
              :key="index"
              :href="`#tab${index +1}`"
              :class="activeTab == index ?'activeTab': 'tab'"
              @click=getItem(item,index)
          >
            {{ item.labelName }}
          </a>
        </div>
        <div class="content" v-if="childrenList.length > 0">
          <div class="detail" id="tab1">
            <div class="wrap" v-for="(a,k) of childrenList" :key="k">
              <div
                  :class="labelName && a.labelName.indexOf(labelName) != -1 ?'title-color' : 'title'">
                {{ a.labelName }}:
              </div>
              <a-space :size="15" direction="horizontal" class="btn-box" v-if="a.items && a.items.length">

                <a-space
                    :size="6"
                    v-for="(tags, tagsIndex) of a.items"
                    :key="tags.labelItemCode + tagsIndex"
                    class="btn-box__tags"
                    @click="handleClickTags(tags, a)"
                    :class="{'btn-box__tags--multi': tags.children && tags.children.length > 1}"
                    style="margin-bottom: 10px"
                >
                  <a-tag
                      v-for="tag of tags.children"
                      :key="tag.id"
                      @click.stop="handleClickTag(tag, tags, a)"
                      :color="(checked[a.id] || []).some(check => check.id == tag.id) ? '#359fec'  : ''"
                  >
                    {{ tag.labelName }}
                  </a-tag>
                </a-space>

              </a-space>
              <div v-else>
                <span style="display:inline-block;padding: 40px 0 0 70px">暂无标签</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content" v-else>
          <span style="display:inline-block;padding: 100px 0 0 70px;font-weight: bold;font-size: 20px">暂无标签</span>
        </div>
      </div>
      <div class="bottom-box">
        <div class="txt-box">
          <span>提示：确定提交后，将以当前打标结果更新诊断标签数据，请仔细确认！</span>
        </div>
        <div class="btn-box">
          <a-button :style="{ marginRight: '8px' }" @click="onCloseDrawer">取消</a-button>
          <a-button type="primary" @click="onSubmit">确定</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import Api from "@/api/tagManage";
import { cloneDeep, flattenDeep, isEqual, keys, remove, some } from "lodash-es";

export default {
  name: "dealerDrawer",
  props: {
    drawerVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "添加标签"
    },
    dealer_id: {
      type: String,
      default: ""
    },
    effectStartTime: {
      type: String,
      default: ""
    },
    effectEndTime: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      form: {},
      btnList: [],
      childrenList: [],
      activeTab: 0,
      checked: {},
      labelName: ""
    };
  },
  computed: {
    // 获取所有选中项
    checkedAll() {
      const checked = cloneDeep(this.checked);
      return flattenDeep(keys(checked).map(id => checked[id].map(item => ({...item, __parent_id__: id}))));
    },
    // 获取所有选中项ID
    checkedIds() {
      return this.checkedAll.map(v => v.id);
    }
  },
  methods: {
    getLabelDisplay(flag = false) {

      let params = {
        dealerId: this.dealer_id,
        effectStartTime: this.effectStartTime + " 00:00:00",
        effectEndTime: this.effectEndTime + " 23:59:59",
        labelName: this.labelName
      };
      Api.getLabelDisplay(params).then((res) => {
        if(res.code == 200) {
          this.$set(this, "form", res.data || {});
          this.childrenList = this.form.list[this.activeTab].children || [];
          if(flag) return;
          const arr = flattenDeep(res.data.list.map(v => v.children));
          arr.forEach(item => {
            this.$set(this.checked, item.id, flattenDeep(item.items?.map(child => child.children?.filter?.(tag => tag.mark == 1) || []) || []));
          });
        } else {
          this.$message.error("获取数据失败");
        }
      });
    },
    onCloseDrawer() {
      this.labelName = "";
      this.$emit("onCloseDrawer", false);
    },
    onSubmit() {
      if(this.checkedIds && this.checkedIds.length > 0) {
        const tagsData = {
          labelIds: this.checkedIds,
          dealerId: this.dealer_id,
          effectStartTime: this.effectStartTime + " 00:00:00",
          effectEndTime: this.effectEndTime + " 23:59:59"
        };
        this.$emit("onAddTag", tagsData);
      } else {
        this.$message.error("请先选择标签");
      }
    },
    handleReset() {
      this.labelName = "";
      this.getLabelDisplay(true);
    },
    handleSearch() {
      this.getLabelDisplay(true);
    },
    getItem(item, index) {
      // console.log(item, "item三级标签");
      this.childrenList = [];
      this.childrenList = item.children || [];
      this.activeTab = index;
    },
    // 处理点击标签盒子
    handleClickTags(tabs, all) {
      let checked = [];
      if(tabs?.children.every(v => cloneDeep(this.checked[all.id])?.some(i => v.id == i.id))) {
        checked = [];
      } else {
        checked = tabs.children;
      }
      this.$set(this.checked, all.id, checked);
    },
    // 处理点击标签
    handleClickTag(tag, tabs, all) {
      let checked = cloneDeep(this.checked[all.id]);
      if(!tabs?.children.some(v => checked?.some(i => i.id == v.id))) {
        checked = [];
      }

      if(some(this.checked[all.id], ["id", tag.id])) {
        checked = remove(checked, (n) => !isEqual(n.id, tag.id));
      } else {
        checked.push(tag);
      }
      this.$set(this.checked, all.id, checked);
    },
    // 处理删除
    handleRemoveTag(tag) {
      let checked = cloneDeep(this.checked[tag.__parent_id__]);
      checked = remove(checked, (n) => !isEqual(n.id, tag.id));
      this.$set(this.checked, tag.__parent_id__, checked);
    },
  }
};
</script>

<style scoped lang="less">
.search-box {
  margin-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;

  /deep/ .panel-search .tools {
    border-left: none;
  }
}

.content-box {
  display: flex;
  height: calc(100vh - 55px - 40px - 100px - 53px);

  .tab-box {
    height: 100%;
    width: 150px;
    text-align: center;
    overflow: auto;

    .tab {
      display: block;
      font-size: 15px;
      font-weight: bold;
      width: 100%;
      height: 40px;
      background: #fff;
      margin: 10px 0;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.65);
    }

    .activeTab {
      display: block;
      font-size: 15px;
      font-weight: bold;
      width: 100%;
      height: 40px;
      background: #eaf5ff;
      margin: 10px 0;
      line-height: 40px;
      color: #40a9ff;
    }
  }

  .content {
    flex: 1;
    padding: 0 10px 0 20px;
    margin-left: 20px;
    height: 100%;
    overflow: auto;
    border-left: 1px solid #e9e9e9;

    .wrap {
      margin-bottom: 60px;
    }

    .title {
      font-size: 15px;
      font-weight: bold;
    }

    .btn-box {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;

      &__tags {
        display: flex;
        flex-wrap: wrap;

        .ant-tag {
          padding: 4px 10px;
          cursor: pointer;
          margin: 4px;

          &:last-child {
            margin-right: 0;
          }
        }

        &--multi {
          border: 1px solid #ccc;
          padding: 4px 8px;
          cursor: pointer;
          border-radius: 4px;
        }
      }

      .box {
        display: inline-block;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 8px;
        margin: 8px;
      }

      .span-tab {
        padding: 4px 8px;
        border: 1px solid #ccc;
        margin: 6px;
        cursor: pointer;
      }

      .span-tab-active {
        padding: 4px 8px;
        border: 1px solid #40a9ff;
        margin: 6px;
        cursor: pointer;
        color: #40a9ff;
        background: #eaf5ff;
      }
    }
  }
}

.bottom-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;

  .txt-box {
    span {
      display: block;
    }
  }

  .btn-box {
    text-align: right;
  }
}

.title-color {
  font-size: 15px;
  font-weight: bold;
  color: #40a9ff;
}
</style>
