<template>
  <a-card>
    <div class="title—text">
      经销商：{{ dealerDetail.name ? dealerDetail.name : dealer_name ? dealer_name : "-" }}
    </div>
    <a-tabs @change="onChangeTabs" v-model="tabActive">
      <a-tab-pane key="0" tab="基础信息">
        <basicInformation
            :dealerId="dealer_id"
            :key="keys"
            @base-info="handleBaseInfo"
        />
      </a-tab-pane>
      <!--<a-tab-pane key="1" tab="学情信息">-->
      <!--</a-tab-pane>-->
      <a-tab-pane key="2" tab="帐号信息">
        <dealerList
            :type="2"
            @searchTabList="searchTabList"
            :columns="account_columns"
            :list="list"
            :isLoading="isLoading"
            :dealerId="dealer_id"
        ></dealerList>
      </a-tab-pane>
      <a-tab-pane key="3" tab="视频信息">
        <dealerList
            :type="3"
            @searchTabList="searchTabList"
            :columns="video_columns"
            :list="list"
            :isLoading="isLoading"
            :dealerId="dealer_id"
        ></dealerList>
      </a-tab-pane>
      <a-tab-pane key="4" tab="直播信息">
        <dealerList
            :type="2"
            @searchTabList="searchTabList"
            :columns="live_columns"
            :list="list"
            :isLoading="isLoading"
            :dealerId="dealer_id"
        ></dealerList>
      </a-tab-pane>
      <a-tab-pane key="5" tab="诊断数据">
        <diagnosticData
            :type="5"
        ></diagnosticData>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import basicInformation from "./components/basic-information";
import dealerList from "./components/dealer_list";
import formatUtil from "@/utils/FormatUtil";
import https from "@/api/kpi.js";
import moment from "moment";
import diagnosticData from "./components/diagnostic-data";


export default {
  name: "dealer-detail",
  data() {
    return {
      dealer_name: this.$route.query.dealer_name || "",
      tabActive: this.$route.query.tabActive || "0",
      dealer_id: this.$route.query.dealer_id,
      video_columns: [
        {
          dataIndex: "platform_name",
          title: "统计平台"
        },
        {
          dataIndex: "time",
          title: "统计时间",
          customRender: (text, record, index) => {
            if(!record.time) return "-";

            const arr = record.time.split("~");
            const arr2 = arr.map(item => {
              return item.split(" ");
            });
            return `${ arr2[0][0] }~${ arr2[1][0] }`;
          }
        },
        {
          dataIndex: "template_state",
          scopedSlots: {customRender: "template_state"},
          title: "使用模板"
        },
        {
          dataIndex: "publish_total",
          title: "发布视频数量"
        },
        {
          dataIndex: "play_volume",
          title: "播放总量"
        },
        {
          dataIndex: "avg_play_volume",
          title: "平均播放量"
        },
        {
          title: "视频增粉量",
          dataIndex: "incr_fans_total"
        },
        {
          dataIndex: "digg_count",
          title: "点赞总量"
        },
        {
          dataIndex: "avg_digg_count",
          title: "平均点赞量"
        },
      ],
      account_columns: [
        {
          dataIndex: "platform_name",
          title: "社媒平台"
        },
        {
          dataIndex: "nickname",
          title: "帐号名称"
        },
        {
          dataIndex: "account_type_name",
          title: "帐号类型"
        },
        {
          dataIndex: "author_id",
          title: "平台ID"
        },
        {
          dataIndex: "fans_count_total",
          title: "粉丝总量",
          customRender: formatUtil.humanReadable
        },
        {
          dataIndex: "like_count_total",
          title: "获赞总量",
          customRender: formatUtil.humanReadable
        },
        {
          dataIndex: "p1_oauth_status",
          scopedSlots: {customRender: "p1_oauth_status"},
          title: "p1授权状态"
        },
        {
          dataIndex: "p1_oauth_time",
          title: "p1授权时间",
          customRender: (text, record, index) => {
            return record.p1_oauth_time || "-";
          }
        },
        {
          dataIndex: "p2_oauth_status",
          scopedSlots: {customRender: "p2_oauth_status"},
          title: "p2授权状态"
        },
        {
          dataIndex: "p2_oauth_time",
          title: "p2授权时间"
        },
      ],
      live_columns: [
        {
          dataIndex: "liveInfo",
          scopedSlots: {customRender: "liveInfo"},
          title: "直播信息"
        },
        {
          dataIndex: "statistics_total_watch_user_time",
          title: "观看人次"
        },
        {
          dataIndex: "statistics_total_watch_user_count",
          title: "观看人数"
        },
        {
          dataIndex: "statistics_max_online_user",
          title: "在线人数峰值"
        },
        {
          dataIndex: "statistics_live_time",
          title: "直播时长（分钟）"
        },
        {
          dataIndex: "statistics_total_comment_count",
          title: "评论数"
        },
        {
          dataIndex: "statistics_total_comment_user_count",
          title: "评论人数"
        },
        {
          dataIndex: "statistics_total_like_count",
          title: "点赞数"
        },
        {
          dataIndex: "statistics_fans_add",
          title: "新增粉丝"
        },
      ],
      list: [],
      isLoading: false,
      keys: new Date().getTime(),
      dealerDetail: {}
    };
  },
  components: {basicInformation, dealerList, diagnosticData},
  mounted() {
    // console.log(this.$route.query.dealer_id);
  },
  methods: {
    onChangeTabs(val) {
      this.tabActive = val;
      switch(this.tabActive) {
        case "0":
          this.keys = new Date().getTime();
          break;
        case "3":
          this.getAwemeSummary({});
          break;
        case "2":
          this.getAuthorList();
          break;
        case "4":
          this.getLiveList();
          break;
      }
    },
    getAwemeSummary(searchData) {
      this.isLoading = true;
      let params = {
        platform_type: searchData.platform_type || "",
        // dealer_id: "1314afanti4",
        dealer_id: this.dealer_id,
        start_time: searchData.start_time || moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00",
        end_time: searchData.end_time || moment().format("YYYY-MM-DD") + " 23:59:59",
      };
      https.getAwemeSummary(params).then(res => {
        this.isLoading = false;
        if(res.code == 200) {
          this.list = res.data || [];
        } else {
          this.list = [];
          this.$message.error(res.message || "获取视频信息失败");
        }
      });
    },
    getAuthorList() {
      this.isLoading = true;
      let params = {
        // dealer_id: "1314afanti4",
        dealer_id: this.dealer_id,
      };
      https.getAuthorList(params).then(res => {
        this.isLoading = false;
        if(res.code == 200) {
          this.list = res.data || [];
        } else {
          this.list = [];
          this.$message.error(res.message || "获取帐号信息失败");
        }
      });
    },
    getLiveList() {
      this.isLoading = true;
      let params = {
        // dealer_id: "1314afanti4",
        dealer_id: this.dealer_id,
      };
      https.getLiveList(params).then(res => {
        this.isLoading = false;
        if(res.code == 200) {
          this.list = res.data || [];
        } else {
          this.list = [];
          this.$message.error(res.message || "获取直播信息失败");
        }
      });
    },
    searchTabList(searchData, type) {
      // console.log(searchData, type);
      switch(type) {
        case 3:
          this.getAwemeSummary(searchData);
          break;
      }
    },
    handleBaseInfo(val) {
      this.dealerDetail = val;
    }
  }
};
</script>

<style scoped lang="less">
.title—text {
  padding: 20px 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
