<template>
  <div>
    <div class="base-button">
      <base-button
          v-if="isShowPermission == 1"
          :type="'primary'"
          :title="'添加问题'"
          @onClickBtn="addProblem"
      />
    </div>
    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="list"
        :row-key="(record, index) => index"
        :pagination="false"
        :scroll="scroll"
    >
      <div slot="problemCause" slot-scope="text, record">
        <div>
          <span>{{ record.problemCause }}</span>
          <span v-if="record.problemSourceType == 'manual'">
          <a-tooltip>
            <template #title>人工添加问题</template>
            <a-icon theme="outlined" type="user" style="padding-left: 10px;cursor: pointer"/>
          </a-tooltip>
        </span>
        </div>

      </div>
      <div slot="showDetails" slot-scope="text, record">
        <div @click="changeBaseButton(4,record)">{{ text }}</div>
      </div>
      <div slot="executionList" slot-scope="text, record">
        <div v-if="record.executionList && record.executionList.length">{{ record.executionList.length }}条</div>
        <div v-else>-</div>
      </div>
      <div slot="action" slot-scope="text, record">
        <div v-if="record.isShowPermission">
          <base-button
              v-if="record.problemStatus == 1"
              :type="'link'"
              :title="'确认'"
              @onClickBtn="changeBaseButton(0,record)"
          />
          <base-button
              v-if="record.problemStatus == 1 || record.problemStatus == 2"
              :type="'link'"
              :title="'删除'"
              @onClickBtn="changeBaseButton(1,record)"
          />
          <base-button
              v-if="record.problemStatus == 3 || record.problemStatus == 2"
              :type="'link'"
              :title="'解决'"
              @onClickBtn="changeBaseButton(2,record)"
          />
          <base-button
              v-if="record.problemStatus == 4"
              :type="'link'"
              :title="'重新打开'"
              @onClickBtn="changeBaseButton(3,record)"
          />
        </div>
        <base-button
            :type="'link'"
            :title="'查看详情'"
            @onClickBtn="changeBaseButton(5,record)"
        />
      </div>
    </a-table>
    <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.page_size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />

    <!--添加问题-->
    <a-modal
        v-model="addProblemVisible"
        title="添加问题"
        width="70%"
        :maskClosable="false"
        @cancel="closeDetailModal"
    >
      <div class="text-top">
        <p>学员名称：{{ dealerInfoData.dealer_name }}</p>
        <p>统计范围：{{ time.startTime || "-" }} 至 {{ time.endTime || "-" }}（{{ time.week || "-" }}）</p>
      </div>
      <div class="table-row">
        <a-row>
          <a-col :span="6"><span class="title-text">问题分类</span></a-col>
          <a-col :span="18"><span class="title-text">问题描述</span></a-col>
        </a-row>
        <div class="submitForm">
          <a-form-model
              ref="submitForm"
              :model="submitForm"
              layout="inline"
              :rules="rules"
          >
            <a-row v-for="(a,k) of submitForm.list" :key="k" style="border-bottom: 1px solid #e8e8e8;padding: 10px">
              <a-col :span="6">
                <a-form-model-item label="">
                  <a-select
                      v-model="a.problemGenerationType"
                      style="width: 150px;"
                      placeholder="请选择"
                      @change="changeProblems(arguments,a)"
                  >
                    <a-select-option
                        v-for="item in problemGenerationTypeList"
                        :key="item.problemGenerationType"
                        :value="item.problemGenerationType"
                    >
                      {{ item.problemGenerationTypeName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="18">
                <a-form-model-item
                    :prop="`list.${k}.problems`"
                    :rules="{
                    required: true,
                    validator: problemsValidator,
                    trigger: 'change',
                  }"
                >
                  <div v-for="(j,u) of a.problems" :key="u" class="form-model-item-icon-box">
                    <a-form-model-item label="" :prop="`list.${k}.problems.${u}.problemCause`">
                      <a-select
                          v-model="j.problemCause"
                          style="width: 320px;"
                          placeholder="请选择"
                      >
                        <a-select-option
                            v-for="item in ((problemGenerationTypeList.find(v => isEqual(v.problemGenerationType, a.problemGenerationType)) || {}).problems) || []"
                            :key="item.id"
                            :value="item.id"
                        >
                          {{ item.problemCause }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-icon
                        type="plus-circle"
                        theme="filled"
                        style="padding:0 12px 0 20px;font-size: 18px"
                        @click="onDescription('add_j',a)"
                    />
                    <a-icon
                        v-if="a.problems.length > 1"
                        type="close-circle"
                        theme="filled"
                        style="font-size: 18px"
                        @click="onDescription('del',a,u)"
                    />
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
            <div class="onDescription_btn">
              <a-button type="dashed" @click="onDescription('add')" style="width: 60%">
                <a-icon type="plus"/>
                添加
              </a-button>
            </div>
          </a-form-model>
        </div>
      </div>

      <template slot="footer">
        <div style="display: flex;justify-content: center">
          <a-button @click="closeDetailModal">取消</a-button>
          <a-button @click="OnSubmit" type="primary">确定</a-button>
        </div>
      </template>
    </a-modal>

    <!--查看明细-->
    <a-modal v-model="detailVisible"
             title="问题跟进"
             width="50%"
             :maskClosable="false"
             @cancel="closeDetailModal">
      <template slot="footer">
        <div style="display: flex;justify-content: center">
          <a-button @click="closeDetailModal" type="primary">我知道了</a-button>
        </div>
      </template>
      <a-form-model
          ref="dataForm"
          :model="dataForm"
          v-bind="layout"
      >
        <a-form-model-item
            label="执行事项"
        >
          <a-textarea
              v-model.trim="dataForm.problem"
              disabled
              :auto-size="{ minRows: 8, maxRows: 16 }"/>
        </a-form-model-item>
        <a-form-model-item
            label="执行事项备注"
        >
          <a-textarea
              v-model.trim="dataForm.method"
              disabled
              :auto-size="{ minRows: 8, maxRows: 16 }"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--解决问题-->
    <a-modal
        v-model="solveProblemVisible"
        title="解决问题"
        width="50%"
        :maskClosable="false"
        @cancel="closeSolveProblemVisibleModal"
    >
      <div class="table-row">
        <div class="submitForm">
          <a-form-model
              ref="solveForm"
              :model="solveForm"
              layout="inline"
              :rules="solveRules"
          >
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="问题状态">
                  <a-radio-group
                      v-model="solveForm.problemStatus"
                  >
                    <a-radio :value="3"> 解决中</a-radio>
                    <a-radio :value="4"> 已解决</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item label="执行事项" prop="executions">
                  <p>请简明扼要地描述您为解决此问题做了哪几项具体工作</p>
                  <a-row>
                    <a-col :span="6">
                      <span class="title-text">序号</span>
                    </a-col>
                    <a-col :span="18">
                      <div>
                        <span class="title-text">事项描述</span>
                      </div>
                    </a-col>
                  </a-row>
                  <a-row
                      v-for="(item,index) of solveForm.executions" :key="index"
                      style="padding: 10px"
                  >
                    <a-col :span="6">
                      <a-form-model-item label="">
                        {{ index + 1 }}
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18" style="cursor: pointer">
                      <div>
                        <a-form-model-item label="">
                          <div style="display: flex;align-items:center">
                            <a-textarea
                                v-model.trim="item.executionContent"
                                placeholder="请输入"
                                :auto-size="{ minRows: 3, maxRows: 5 }"
                                style="width: 300px"
                                :maxLength="100"
                                :disabled="item.executionContentId"
                            />
                            <span
                                style="position: absolute;
                                right: 6px;
                                top: 38px">
                              {{ item.executionContent.length || 0 }}/100
                            </span>
                            <a-icon
                                type="close-circle"
                                theme="filled"
                                style="font-size: 18px;position: absolute;right: -26px;top: 30px"
                                @click="onDescription('executionContent',item,index)"
                            />
                          </div>
                        </a-form-model-item>
                      </div>
                    </a-col>
                  </a-row>
                  <div class="onDescription_btn">
                    <a-button type="dashed" @click="onDescription('event_list')" style="width: 100%">
                      <a-icon type="plus"/>
                      添加
                    </a-button>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item label="执行事项备注">
                  <p>可登记问题本身或跟进过程中需要补充的信息</p>
                  <a-textarea
                      v-model.trim="solveForm.remark"
                      placeholder="请输入"
                      :auto-size="{ minRows: 4, maxRows: 8 }"
                      :maxLength="500"
                      style="width: 400px;position: relative"
                  />
                  <span style="position: absolute;
                                right: 4px;
                                bottom: -12px">{{ solveForm.remark.length || 0 }}/500</span>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>

      <template slot="footer">
        <a-button @click="closeSolveProblemVisibleModal">取消</a-button>
        <a-button type="primary" @click="submitSolveForm">确定</a-button>
      </template>
    </a-modal>

    <!--问题记录重复提示-->
    <a-modal v-model="drepeatRecordVisible"
             :title="err_message"
             width="50%"
             :maskClosable="false"
             @cancel="drepeatRecordVisible = false"
             :footer="null"
    >
      <div>
        <div v-if="repeatRecordList">
          <p v-for="(item,index) of repeatRecordList" :key="index">
            <span style="padding-right: 4px">{{ index + 1 }}、</span>
            {{ item }}
          </p>
        </div>
      </div>
    </a-modal>

    <!--问题详情-->
    <a-drawer
        title="问题详情"
        placement="right"
        width="60%"
        :maskClosable="false"
        :visible="problemDetailVisible"
        @close="handleCancelProblemDetailVisible"
    >
      <a-form-model
          ref="form"
          :model="form"
          layout="inline"
      >

        <a-row>
          <a-col :span="10">
            <p class="h2-title"><span></span>基本信息</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="问题描述">
              {{ form.problemCause || "-" }}
              <a-tag color="blue" style="margin-left: 10px">
                {{
                  form.problemStatus == 1 ? "待确认" : form.problemStatus == 2 ? "已确认待解决" : form.problemStatus == 3 ? "解决中" : form.problemStatus == 4 ? "已解决" : "-"
                }}
              </a-tag>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="问题分类">
              {{ form.problemGenerationType || "-" }}
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="问题来源">
              {{ form.problemSourceType }}
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="问题持续时间">
              {{ form.problemDuration || 0 }}天
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="学员名称">
              {{ dealerInfoData.dealer_name || "-" }}
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="更多信息">
              <a-button type="link" @click="moreInfoModal = true">点击查看</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-top: 20px">
          <a-col :span="10">
            <p class="h2-title"><span></span>解决方案建议</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <div class="principalProblemSuggestion-box">
              <p class="item-title">对品牌方的解决方案建议：</p>
              <p v-if="form.principalProblemSuggestion && form.principalProblemSuggestion.length"
                 v-for="(item,index) of form.principalProblemSuggestion"
                 :key="index"
                 class="white-space">
                {{ index + 1 }}、{{ item }}
              </p>
              <p v-else>暂无</p>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <div class="principalProblemSuggestion-box">
              <p class="item-title">对阿凡提的解决方案建议：</p>
              <p v-if="form.problemSuggestion && form.problemSuggestion.length" v-for="(a,k) of form.problemSuggestion"
                 :key="k"
                 class="white-space">
                {{ k + 1 }}、{{ a }}
              </p>
              <p v-else>暂无</p>
            </div>
          </a-col>
        </a-row>

        <a-row style="padding-top: 20px">
          <a-col :span="10">
            <p class="h2-title"><span></span>问题跟进</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <div class="principalProblemSuggestion-box">
              <p class="item-title">执行事项：</p>
              <p v-if="form.executionContent && form.executionContent.length" v-for="(a,k) of form.executionContent"
                 :key="k"
                 class="white-space">
                {{ k + 1 }}、{{ a }}
              </p>
              <p v-else>暂无</p>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <div class="principalProblemSuggestion-box">
              <p class="item-title">执行事项备注：</p>
              <p v-if="form.executionRemark"
                 class="white-space">
                {{ form.executionRemark }}
              </p>
              <p v-else>暂无</p>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

    </a-drawer>


    <!--查看更多信息-->
    <a-modal v-model="moreInfoModal"
             title="更多信息"
             width="40%"
             :maskClosable="false"
             :footer="false"
    >
      <div class="more-box">
        <a-row>
          <a-col span="24">统计范围：
            {{ time.startTime || "-" }} 至 {{ time.endTime || "-" }}
            （{{ time.week || "-" }}）
          </a-col>
        </a-row>
        <a-row>
          <a-col span="24">问题创建：{{ form.creatorOauthName || "-" }} {{ form.ctime || "-" }}</a-col>
        </a-row>
        <a-row>
          <a-col span="24">问题确认：{{ form.problemConfirmer || "-" }} {{ form.problemConfirmationTime || "-" }}</a-col>
        </a-row>
        <a-row>
          <a-col span="24">问题解决：{{ form.problemSolver || "-" }} {{ form.problemSolutionTime || "-" }}</a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import api from "@/api/problem_table_list";
import { flattenDeep, isEqual, uniq } from "lodash-es";

function problemsValidator(rule, value, callback) {
  if(value?.every(v => !v.problemCause)) {
    callback(new Error("请选择问题描述"));
  } else {
    callback();
  }
}

export default {
  name: "problem_table_list",
  props: {
    columns: {
      type: Array,
      default() {
        return [
          {
            title: "问题描述",
            dataIndex: "problemCause",
            width: 160,
            scopedSlots: {customRender: "problemCause"},
          },
          {
            title: "问题分类",
            dataIndex: "problemGenerationType",
            width: 100,
            customRender: (text) => {
              return text || "-";
            },
          },
          {
            title: "问题持续时间",
            dataIndex: "problemDuration",
            width: 100,
            customRender: (text) => {
              return text + "天" || "-";
            },
          },
          // {
          //   title: "问题来源",
          //   dataIndex: "",
          //   width: 140,
          //   customRender: (text) => {
          //     return text || "-";
          //   },
          // },
          {
            title: "执行事项",
            dataIndex: "",
            scopedSlots: {customRender: "executionList"},
            width: 140,
          },
          {
            title: "问题状态",
            dataIndex: "problemStatus",
            width: 130,
            customRender: (text) => {
              return text == 1 ? "待确认" : text == 2 ? "已确认待解决" : text == 3 ? "解决中" : text == 4 ? "已解决" : "-";
            },
          },
          {
            title: "操作",
            dataIndex: "",
            scopedSlots: {customRender: "action"},
            width: 200,
          },

          // {
          //   title: "更新信息",
          //   dataIndex: "",
          //   width: 100,
          //   customRender: (text) => {
          //     return text || "-";
          //   },
          // },
        ];
      },
    },
    time: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    time: {
      handler(Val) {
        console.log(Val);
        this.getPdmsRecordList();
        this.getRecordAuth();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      detailVisible: false,
      addProblemVisible: false,
      solveProblemVisible: false,
      drepeatRecordVisible: false,
      problemDetailVisible: false,
      moreInfoModal: false,
      loading: false,
      list: [],
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      scroll: {
        x: 1000,
        y: 600,
      },
      dataForm: {},
      layout: {
        labelCol: {span: 3},
        wrapperCol: {span: 16},
      },
      submitForm: {
        list: [
          {
            problemGenerationType: "store",
            problemGenerationTypeName: "店端",
            problems: [
              {problemCause: undefined, id: ""},
            ],
          },
        ],
      },
      problemGenerationTypeList: [],
      problemCauseList: [],
      rules: {
        title: [
          {
            required: true,
            message: "请输入工单标题",
            trigger: "blur",
          },
        ],
        list: {
          required: true,
          trigger: "blur",
        },
      },
      problemForm: {
        list: [
          {
            meg: "",
            id: "",
          },
        ],
      },
      problemRules: {
        problemRules: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
      traineeId: this.$route.query.dealer_id || "",
      end_date: "",
      start_date: "",
      dealerInfoData: {
        dealer_id: this.$route.query.dealer_id || "",
        dealer_name: this.$route.query.dealer_name || "",
      },
      addRecordAuth: 1,
      isShowPermission: 1,
      solveForm: {
        executions: [
          {
            executionContent: "",
            executionContentId: undefined,
          },
        ],
        remark: "",
        problemStatus: 3,
        problemBatchNo: undefined,
        // problemStatus:'',
      },
      solveRules: {
        executions: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if(value?.every(v => !v.executionContent)) {
                callback("需至少添加一条执行事项");
              } else {
                callback();
              }
            },
          },
        ],
      },
      repeatRecordList: [],
      err_message: "",
      form: {},
    };
  },
  mounted() {
    this.getPdmsSuggestionsAll();
  },
  methods: {
    problemsValidator,
    isEqual,
    getRecordAuth() {
      api.getRecordAuth(this.traineeId, {
        startDate: this.time.startTime,
        endDate: this.time.endTime,
      }).then((res) => {
        if(res.code == 200) {
          this.addRecordAuth = res.data.isHasPermission;
          this.isShowPermission = res.data.isShowPermission;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getPdmsRecordList() {
      this.loading = true;
      let params = {
        startDate: this.time.startTime,
        endDate: this.time.endTime,
        page: this.pagination.current,
        size: this.pagination.page_size,
        traineeId: this.traineeId,
      };
      api.getPdmsRecordList(params).then((res) => {
        if(res.code == 200) {
          this.list = res.data.list || [];
          this.pagination.total = res.data.total || 0;
          this.loading = false;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message);
          this.loading = false;
        }
      });
    },
    getPdmsSuggestionsAll() {
      api.getPdmsSuggestionsAll().then((res) => {
        if(res.code == 200) {
          this.problemGenerationTypeList = res.data || [];
          this.problemCauseList = this.problemGenerationTypeList.filter(v => v.problemGenerationType == "store")[0].problems || [];
        } else {
          this.$message.error(res.message || "获取下拉数据失败");
        }
      });
    },
    changeBaseButton(type, record) {
      console.log(type, record);
      let that = this;
      if(type !== 5 && !record.isHasPermission) {
        this.$warning({
          title: "抱歉，您还没有此功能的操作权限",
        });
        return;
      }
      switch(type) {
        case 0:
          console.log("确认");
          that.$confirm({
            title: "确定存在该问题？",
            okText: "确定",
            cancelText: "取消",
            onOk: async() => {
              that.putPdmStatus({
                problemBatchNo: record.problemBatchNo,
                problemStatus: 2,
              });
            },
          });
          break;
        case 1:
          console.log("删除");
          that.$confirm({
            title: "删除后，该问题将从此问题列表移除。确定删除？",
            okText: "删除",
            cancelText: "取消",
            onOk: async() => {
              api.delPdm(record.problemBatchNo).then((res) => {
                if(res.code == 200) {
                  this.$message.success("删除成功");
                  this.getPdmsRecordList();
                } else {
                  this.$message.error(res.message);
                }
              });
            },
          });
          break;
        case 2:
          console.log("解决");
          this.getProblemBatchNo(record.problemBatchNo);
          break;
        case 3:
          console.log("重新打开");
          that.$confirm({
            title: "重新打开后，该问题将重置为解决中状态。确定打开？",
            okText: "打开",
            cancelText: "取消",
            onOk: async() => {
              that.putPdmStatus({
                problemBatchNo: record.problemBatchNo,
                problemStatus: 3,
              });
            },
          });
          break;
        case 4:
          console.log("查看明细");
          this.detailVisible = true;
          break;
        case 5:
          console.log("查看详情");
          this.getProblemDetail(record);
          break;
      }
    },
    handlePaginationChange(current, page_size) {
      this.pagination.current = current;
      this.pagination.page_size = page_size;
      this.getPdmsRecordList();
    },
    addProblem() {
      console.log("addProblem");
      if(this.addRecordAuth == 1) {
        this.addProblemVisible = true;
        this.submitForm = {
          list: [
            {
              problemGenerationType: "store",
              problemGenerationTypeName: "店端",
              problems: [
                {problemCause: undefined, id: ""},
              ],
            },
          ],
        };
      } else {
        this.$warning({
          title: "抱歉，您还没有此功能的操作权限",
        });
      }
    },
    closeDetailModal() {
      // this.$refs.submitForm.resetFields();
      this.addProblemVisible = false;
      this.submitForm = {
        list: [
          {
            problemGenerationType: "store",
            problemGenerationTypeName: "店端",
            problems: [
              {problemCause: undefined, id: ""},
            ],
          },
        ],
      };
      this.getPdmsRecordList();
    },
    OnSubmit() {
      this.$refs.submitForm.validate((valid) => {
        if(valid) {
          let arr = [];
          if(this.submitForm.list) arr = this.submitForm.list.map(item => item.problems.map(v => v.problemCause));
          const newArr = uniq(flattenDeep(arr));
          let data = {
            startDate: this.time.startTime,
            endDate: this.time.endTime,
            problemDiagnosisSuggestionIdArray: newArr,
            problemSourceType: "manual",
            traineeId: this.traineeId,
          };
          api.postPdmSrecord(data).then((res) => {
            if(res.code == 200) {
              this.$message.success("保存成功");
              this.closeDetailModal();
            } else {
              if(res.data) {
                this.err_message = res.message || "";
                this.drepeatRecordVisible = true;
                this.repeatRecordList = res.data?.repeatRecordList || [];
              } else {
                this.$message.error(res.message);
              }

            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onDescription(type, item, index) {
      console.log(type, item, index);
      switch(type) {
        case "add":
          console.log("add");
          this.submitForm.list.push(
              {
                problemGenerationType: "store",
                problemGenerationTypeName: "店端",
                problems: [
                  {problemCause: undefined, id: ""},
                ],
              },
          );
          this.problemCauseList = this.problemGenerationTypeList.filter(v => v.problemGenerationType == "store")[0].problems || [];
          break;
        case "add_j":
          console.log("add_j");
          item.problems.push(
              {problemCause: undefined, id: ""},
          );
          this.$set(this, item, item);
          break;
        case "del":
          console.log("del");
          item.problems.splice(index, 1);
          this.$set(this, item, item);
          break;
        case "event_list":
          console.log("event_list");
          this.solveForm.executions.push(
              {
                executionContent: "",
                executionContentId: undefined,
              },
          );
          break;
        case "executionContent":
          console.log("executionContent");
          this.$confirm({
            title: "删除后，该执行事项将移除，确定删除？",
            okText: "确定",
            cancelText: "取消",
            onOk: async() => {
              this.solveForm.executions.splice(index, 1);
            },
          });
          break;
      }
    },
    changeProblems(e, a) {
      this.$set(a, "problems", [{problemCause: undefined, id: ""}]);
      console.log(this.problemGenerationTypeList);
      this.problemCauseList = this.problemGenerationTypeList.filter(v => v.problemGenerationType == a.problemGenerationType)[0].problems || [];
    },
    putPdmStatus(data) {
      api.putPdmStatus(data).then((res) => {
        if(res.code == 200) {
          this.$message.success("操作成功");
          this.getPdmsRecordList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    closeSolveProblemVisibleModal() {
      this.solveProblemVisible = false;
      this.$refs.solveForm.resetFields();
      this.solveForm = {
        executions: [
          {
            executionContent: "",
            executionContentId: undefined,
          },
        ],
        remark: "",
        problemStatus: 3,
        problemBatchNo: undefined,
      };
      this.getPdmsRecordList();
    },
    submitSolveForm() {
      this.$refs.solveForm.validate((valid) => {
        if(valid) {
          console.log(this.solveForm);
          const executions = this.solveForm.executions?.filter(v => v.executionContent);
          api.putPdmsSolve({
            executions,
            problemBatchNo: this.solveForm.problemBatchNo,
            problemStatus: this.solveForm.problemStatus,
            remark: this.solveForm.remark,
          }).then((res) => {
            if(res.code == 200) {
              this.$message.success("保存成功");
              this.closeSolveProblemVisibleModal();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getProblemBatchNo(problemBatchNo) {
      api.getProblemBatchNo(problemBatchNo).then((res) => {
        if(res.code == 200) {
          this.$set(this, "solveForm", {
            executions: res.data.executions || [{
              executionContent: "",
              executionContentId: undefined,
            }],
            remark: res.data.remark || "",
            problemStatus: res.data.problemStatus || 3,
            problemBatchNo: res.data.problemBatchNo || undefined,
          });
          this.solveProblemVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getProblemDetail(record) {
      api.getProblemDetail({id: record.id}).then((res) => {
        if(res.code == 200) {
          this.problemDetailVisible = true;
          this.form = {
            ...record,
            ...res.data || {}
          };
          console.log(this.form);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleCancelProblemDetailVisible() {
      this.problemDetailVisible = false;
    }
  },
};
</script>

<style scoped lang="less">
.base-button {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.text-top {
  padding-bottom: 20px;
}

.table-row {
  .title-text {
    font-size: 14px;
    font-weight: bold;
  }
}

.submitForm {
  //padding-top: 15px;
}

.onDescription_btn {
  padding: 20px 0;
}

.form-model-item-icon-box {
  display: flex;
  align-items: center;
}

.h2-title {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    width: 4px;
    height: 24px;
    background: #40a9ff;
    margin-right: 10px;
  }
}

.mouseenter_box_style {
  padding: 10px;
  background: #e2ecfa;
  position: relative;
}

.more-box {
  .ant-row {
    padding-bottom: 12px;
  }
}

.principalProblemSuggestion-box {
  width: 640px;
  height: 260px;
  background: #f5f5f5;
  border: 1px solid #dcdee0;
  overflow-y: auto;
  padding: 12px;
  margin-bottom: 20px;
}

.item-title {
  font-weight: bold;
}

.white-space {
  white-space: pre;
}
</style>
