<template>
  <div>
    <a-row>
      <a-col :span="3" class="col_text"><span>经销商名称:</span></a-col>
      <a-col :span="21">{{ data.name || "-" }}</a-col>
    </a-row>
    <a-row style="padding: 10px 0">
      <a-col :span="3" class="col_text"><span>经销商ID:</span></a-col>
      <a-col :span="21">{{ data.id || "-" }}</a-col>
    </a-row>
    <a-row>
      <a-col :span="3" class="col_text"><span>经销商编码:</span></a-col>
      <a-col :span="21">{{ data.store_code || "-" }}</a-col>
    </a-row>
    <a-row style="padding: 10px 0">
      <a-col :span="3" class="col_text"><span>公司名称:</span></a-col>
      <a-col :span="21">{{ data.company_name || "-" }}</a-col>
    </a-row>
    <a-row>
      <a-col :span="3" class="col_text"><span>联系电话:</span></a-col>
      <a-col :span="21">{{ data.sellphone || "-" }}</a-col>
    </a-row>
    <a-row style="padding: 10px 0">
      <a-col :span="3" class="col_text"><span>品牌:</span></a-col>
      <a-col :span="21">{{ data.principal_name || "-" }}</a-col>
    </a-row>
    <a-row>
      <a-col :span="3" class="col_text"><span>地址:</span></a-col>
      <a-col :span="21">{{ data.address || "-" }}</a-col>
    </a-row>
    <a-row style="padding: 10px 0">
      <a-col :span="3" class="col_text"><span>地图坐标:</span></a-col>
      <a-col :span="21">经度：{{ data.lng || "-" }},纬度：{{ data.lat || "-" }}</a-col>
    </a-row>
    <div class="map_box">
      <div class="address-box">
        <a-input
            placeholder="请输入关键字搜索"
            enter-button="搜索"
            size="large"
            v-model="keyword"
            @search="onSearchAddress"
        />
      </div>
      <baidu-map
          class="bm-view"
          ak="goxmGra9MUHi3BtEWTn53Mn6EgtbHd4Y"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          @ready="handler"
          @click="onMapInfo"
      >
        <BmMarker
            :position="markerPoint"
        />
        <bm-local-search
            class="search-box"
            :keyword="keyword"
            :auto-viewport="true"
            :location="location"
            @infohtmlset="onInfohtmlSet"
        ></bm-local-search>
        <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"/>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"/>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :panel="true" :autoViewport="true" :showAddressBar="true"
                        :autoLocation="true"/>
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"/>

      </baidu-map>
    </div>
  </div>
</template>

<script>
import {
  BaiduMap,
  BmGeolocation,
  BmInfoWindow,
  BmLocalSearch,
  BmMarker,
  BmNavigation,
  BmScale,
  BmView,
  BmMapType
} from "vue-baidu-map";
import https from "@/api/kpi.js";

export default {
  name: "basic-information",
  data() {
    return {
      data: {},
      //地图
      center: {lng: 0, lat: 0},
      zoom: 15,
      location: undefined,
      keyword: undefined,
      readonly: true,
      is_confirm: false,
      now_point: null,
      point_lat: "",
      point_lng: "",
      is_handler_map: true,
      is_search_address: true,
      search_address: "",
      markerPoint: {lng: null, lat: null},
    };
  },
  props: {
    dealerId: {type: String}
  },
  components: {
    BaiduMap, BmScale, BmNavigation, BmGeolocation, BmView, BmLocalSearch, BmMarker, BmInfoWindow, BmView,BmMapType
  },
  mounted() {
    this.getDealerInfo();
  },
  methods: {
    //点击检索地区
    onInfohtmlSet(data) {
      console.log("onInfohtmlSet", data);
      if(data) {
        this.$set(this, "point_lng", data.point.lng || "");
        this.$set(this, "point_lat", data.point.lat || "");
        this.$set(this, "now_point", data.point || null);
        this.is_search_address = true;
        this.search_address = data.address;
      }
    },
    //搜索地址
    onSearchAddress(value) {
      console.log(value);
      this.keyword = value;
    },
    //地图初始化
    handler({BMap, map}) {
      if(this.is_handler_map) {
        const that = this;
        // 获取自动定位方法
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(
            function(r) {
              that.$set(that, "center", {lng: r.longitude, lat: r.latitude});
            },
            {enableHighAccuracy: true}
        );
      } else {
        this.center = {lng: this.point_lng, lat: this.point_lat};
      }
    },
    //单击拾取地图信息
    onMapInfo(e) {
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;

      this.$set(this, "point_lng", e.point.lng || "");
      this.$set(this, "point_lat", e.point.lat || "");
      this.$set(this, "now_point", e.point || null);
      this.is_search_address = false;
    },
    getDealerInfo() {
      https.getDealerInfo({dealer_id: this.dealerId}).then(res => {
        if(res.code === 200) {
          this.data = res.data || {};
          const data = res.data;
          console.log(data);
          // this.$set(this.center, "lat", res.data.lat || undefined);
          // this.$set(this.center, "lng", res.data.lng || undefined);
          this.$set(this, "point_lng", res.data.lng || undefined);
          this.$set(this, "point_lat", res.data.lat || undefined);
          this.$set(this, "markerPoint", {lng: data.lng, lat: data.lat});
          this.$set(this, "keyword", data.address);
          this.$emit('base-info', this.data)
        } else {
          this.data = {};
          this.$message.error(res.message || "获取基础信息失败");
        }
      });
    },
  }
};
</script>

<style scoped lang="less">
.map_box {
  position: relative;
}

.col_text {
  font-size: 14px;
  font-weight: bold;
}

.bm-view {
  position: relative;
  width: 96%;
  height: 700px;
}

.address-box {
  width: 50%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  left: 50px;
  z-index: 999;
  box-shadow: 6px 6px 3px #ccc;

  & + .address-box {
    margin-top: 10px;
  }
}

.map-box {
  margin-top: 15px;
  width: 100%;
  height: 100%;
}

.search-box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24%;
  background: #fff;
  overflow: auto;
}

/deep/ .BMap_cpyCtrl {
  display: none;
}
</style>
