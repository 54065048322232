<template>
  <div id="top">
    <a-tabs type="card" @change="onChangeTabs" v-model="tabActive">
      <a-tab-pane key="0" tab="标签">
        <div v-if="diagnosData && diagnosData.list && diagnosData.list.length > 0">
          <div class="search-box">
            <a-form-model class="panel panel-default panel-search" :model="form" layout="inline">
              <a-form-model-item label="生效周期">
                <div class="picker-wrap">
                  <a-radio-group button-style="solid" v-model="ctimeType" @change="handleCtimeTypeChange">
                    <a-radio-button
                      v-for="item in ctimeTypeList"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-radio-button>
                  </a-radio-group>
                  <div class="picker-box">
                    <div class="picker">
                      <a-icon type="calendar" class="calendar" v-if="pickerTime" />
                      {{ pickerTime || "请选择时间" }}
                    </div>
                    <a-week-picker
                      v-model="weekTime"
                      format="YYYY-MM-DD"
                      placeholder="请选择时间"
                      @change="onChangeTime"
                      style="width: 100%;"
                      :allowClear="false"
                    />
                  </div>
                </div>
              </a-form-model-item>
            </a-form-model>
          </div>

          <div class="info-box">
            <a-row>
              <a-col :span="21">
                <div>
                  <span>更新时间：</span>
                  <span>{{ diagnosData.mtime || "-" }}</span>
                </div>
                <div>
                  <span>更新人：</span>
                  <span>{{ diagnosData.actorOauthName || "-" }}</span>
                </div>
              </a-col>
              <a-col :span="3" class="flex-end">
                <a-button type="primary" @click="onEditTag('edit')">编辑标签</a-button>
              </a-col>
            </a-row>
          </div>
          <div class="content-box">
            <div class="content" v-for="(item,index) of diagnosData.list" :key="index">
              <div class="main-title">{{ item.labelName }}</div>
              <div class="table">
                <div class="row" v-for="(a,k) of item.children" :key="k">
                  <div class="col name">
                    <a-tooltip>
                      <template #title> {{ a.labelName }}</template>
                      {{ a.labelName }}
                    </a-tooltip>
                  </div>
                  <div class="col">
                    <a-tooltip>
                      <template #title>
                        <span :style="(a.children || []).every(v => v.id == -1) ? { color: '#ffaf4c' } : ''"
                              class="text">
                        {{ (a.children || []).map(v => v.labelName).join("，") }}
                      </span>
                      </template>
                      <span :style="(a.children || []).every(v => v.id == -1) ? { color: '#ffaf4c' } : ''" class="text">
                        {{ (a.children || []).map(v => v.labelName).join("，") }}
                      </span>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-box-NOlist" v-else>
          <div class="content">
            <p>该周期内无诊断标签</p>
            <a-button type="primary" @click="onEditTag('add')">添加标签</a-button>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="1" tab="问题">
        <problemPage ref="problemPage" />
      </a-tab-pane>
      <!-- <a-tab-pane key="1" tab="诊断意见">
         <div class="content-box">
           <div class="content">
             <div class="main-title">直播诊断意见</div>
             <div v-if="suggestionData.liveQuestion || liveDiagnosticData.liveSuggestion">
               <div class="info-box">
                 <a-row>
                   <a-col :span="21">
                     <div>
                       <span>更新时间：</span>
                       <span>{{ suggestionData.liveMtime }}</span>
                     </div>
                     <div>
                       <span>更新人：</span>
                       <span>{{ suggestionData.liveActorOauthName }}</span>
                     </div>
                   </a-col>
                   <a-col :span="3" class="flex-end">
                     <a-button type="primary" @click="openDiagnosticModal('live', '直播诊断意见')">编辑断意见</a-button>
                   </a-col>
                 </a-row>
               </div>
               <div class="table1">
                 <div class="row">
                   <div class="col name">直播问题</div>
                   <div class="col">{{ suggestionData.liveQuestion }}</div>
                 </div>
                 <div class="row">
                   <div class="col name">解决办法</div>
                   <div class="col">{{ suggestionData.liveSuggestion }}</div>
                 </div>
               </div>
             </div>
             <div class="content-box-NOlist" v-else>
               <div class="content">
                 <p>该周期内无直播诊断意见</p>
                 <a-button type="primary" @click="openDiagnosticModal('live', '直播诊断意见')">添加诊断意见</a-button>
               </div>
             </div>
           </div>
           <div class="content">
             <div class="main-title">短视频诊断意见</div>
             <div v-if="suggestionData.videoQuestion || suggestionData.videoSuggestion">
               <div class="info-box">
                 <a-row>
                   <a-col :span="21">
                     <div>
                       <span>更新时间：</span>
                       <span>{{ suggestionData.videoMtime }}</span>
                     </div>
                     <div>
                       <span>更新人：</span>
                       <span>{{ suggestionData.videoActorOauthName }}</span>
                     </div>
                   </a-col>
                   <a-col :span="3" class="flex-end">
                     <a-button type="primary" @click="openDiagnosticModal('video', '短视频诊断意见')">编辑断意见
                     </a-button>
                   </a-col>
                 </a-row>
               </div>
               <div class="table1">
                 <div class="row">
                   <div class="col name">短视频问题</div>
                   <div class="col">{{ suggestionData.videoQuestion }}</div>
                 </div>
                 <div class="row">
                   <div class="col name">解决办法</div>
                   <div class="col">{{ suggestionData.videoSuggestion }}</div>
                 </div>
               </div>
             </div>
             <div class="content-box-NOlist" v-else>
               <div class="content">
                 <p>该周期内无短视频诊断意见</p>
                 <a-button type="primary" @click="openDiagnosticModal('video', '短视频诊断意见')">添加诊断意见</a-button>
               </div>
             </div>
           </div>
         </div>
       </a-tab-pane>-->
    </a-tabs>

    <a href="#top" class="top">
      <a-icon type="to-top" class="icon" />
    </a>

    <!-- 添加/编辑诊断意见-->
    <a-modal v-model="diagnosticVisible" :title="diagnosticTitle" width="1000px" @cancel="closeDiagnosticModal">
      <template slot="footer">
        <a-button @click="closeDiagnosticModal">取 消</a-button>
        <a-button type="primary" :loading="isSubmit" :disabled="isSubmit" @click="submitDiagnostic">确 定</a-button>
      </template>
      <a-form-model
        ref="dataForm"
        :model="dataForm"
        v-bind="layout"
        :rules="rules"
      >
        <a-form-model-item
          :label="`${diagnosticType == 'live' ? '直播' : '短视频'}问题`"
          prop="problem"
        >
          <a-textarea
            v-model="dataForm.problem"
            :maxLength="1000"
            placeholder="请输入"
            :auto-size="{ minRows: 6, maxRows: 13 }" />
        </a-form-model-item>
        <a-form-model-item
          label="解决办法"
          prop="method"
        >
          <a-textarea
            v-model="dataForm.method"
            :maxLength="1000"
            placeholder="请输入"
            :auto-size="{ minRows: 6, maxRows: 13 }" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 添加标签 -->
    <DealerDrawer
      ref="DealerDrawer"
      :drawerVisible="drawerVisible"
      :title="drawerTitle"
      :dealer_id="dealer_id"
      :effectStartTime="form.startTime"
      :effectEndTime="form.endTime"
      @onCloseDrawer="onCloseDrawer"
      @onAddTag="onAddTag"
    />
  </div>
</template>

<script>
import DealerDrawer from "@/components/DealerDrawer/DealerDrawer";
import Api from "@/api/tagManage";
import problemPage from "./problem_page";

export default {
  components: {
    DealerDrawer,
    problemPage,
  },
  data() {
    return {
      form: {
        startTime: this.$moment().startOf("week").format("YYYY-MM-DD"),
        endTime: this.$moment().endOf("week").format("YYYY-MM-DD"),
      },
      pickerTime: `本周 | ${this.$moment().startOf("week").format("YYYY-MM-DD")} 至 ${this.$moment().endOf("week").format("YYYY-MM-DD")}`,
      weekTime: this.$moment(),
      ctimeType: "0", // 1, 2
      ctimeTypeList: [
        {label: "本周", value: "0"},
        {label: "上周", value: "-7"},
      ],
      diagnosticVisible: false,
      diagnosticTitle: "诊断意见",
      diagnosticType: "",
      dataForm: {
        problem: "",
        method: "",
      },
      isSubmit: false,
      liveDiagnosticData: {},
      videoDiagnosticData: {},
      layout: {
        labelCol: {span: 3},
        wrapperCol: {span: 21},
      },
      drawerVisible: false,
      drawerTitle: "添加标签",
      tabActive: this.$route.query.diagnosticTabs || "0",
      dealer_id: this.$route.query.dealer_id,
      diagnosData: {},
      suggestionData: {},
      rules: {
        problem: [
          {required: true, message: "请输入", trigger: "blur"},
        ],
        method: [
          {required: true, message: "请输入", trigger: "blur"},
        ],
      },
      authority: false,
      suggestionAuthority: false,
    };
  },
  mounted() {
    this.getLabelDiagnose();
  },
  methods: {
    getLabelDiagnose() {
      let params = {
        dealerId: this.dealer_id,
        effectStartTime: this.form.startTime + " 00:00:00",
        effectEndTime: this.form.endTime + " 23:59:59",
      };
      Api.getLabelDiagnose(params).then((res) => {
        if (res.code == 200) {
          this.authority = res.data.authority;
          this.diagnosData = res.data.res || {};
        } else {
          this.authority = false;
          this.diagnosData = {};
          this.$message.error("获取数据失败");
        }
      });
    },
    getLabelSuggestion() {
      let params = {
        dealerId: this.dealer_id,
        effectStartTime: this.form.startTime + " 00:00:00",
        effectEndTime: this.form.endTime + " 23:59:59",
      };
      Api.getLabelSuggestion(params).then((res) => {
        if (res.code == 200) {
          this.suggestionAuthority = res.data.authority;
          this.suggestionData = res.data.res || {};
        } else {
          this.suggestionAuthority = false;
          this.suggestionData = {};
          this.$message.error("获取数据失败");
        }
      });
    },
    handleCtimeTypeChange() {
      let time = this.$moment();
      switch (this.ctimeType) {
        case "0":
          this.onChangeTime(time);
          break;
        case "-7":
          this.onChangeTime(this.$moment(time.startOf("week").subtract("week", 1).format("YYYY-MM-DD")));
          break;

      }
    },
    onChangeTime(date) {
      if (date) {
        let week = "0";
        let startTime = date.startOf("week").format("YYYY-MM-DD");

        if (startTime == this.$moment().startOf("week").format("YYYY-MM-DD")) {
          week = "0";
          this.ctimeType = "0";
        } else if (startTime == this.$moment().startOf("week").subtract("week", 1).format("YYYY-MM-DD")) {
          week = "-7";
          this.ctimeType = "-7";
        } else {
          week = date.week();
          this.ctimeType = "";
        }
        this.form.startTime = startTime;
        this.form.endTime = date.endOf("week").format("YYYY-MM-DD");
        this.pickerTime = `${this.formatWeek(week)} | ${startTime} 至 ${this.form.endTime}`;
        this.weekTime = this.form.endTime;
        this.onChangeTabs();
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
        this.pickerTime = "";
        this.weekTime = "";
      }
    },
    formatWeek(week) {
      let txt = "";
      switch (week) {
        case "0":
          txt = "本周";
          break;
        case "-7":
          txt = "上周";
          break;
        default:
          txt = `第${week}周`;
          break;
      }
      return txt;

    },
    openDiagnosticModal(type, title) {
      if (this.suggestionAuthority) {
        this.diagnosticType = type;
        this.diagnosticTitle = title;
        this.diagnosticVisible = true;

        switch (type) {
          case "live":
            this.$set(this.dataForm, "method", this.suggestionData.liveSuggestion);
            this.$set(this.dataForm, "problem", this.suggestionData.liveQuestion);
            break;
          case "video":
            this.$set(this.dataForm, "method", this.suggestionData.videoSuggestion);
            this.$set(this.dataForm, "problem", this.suggestionData.videoQuestion);
            break;
        }
      } else {
        this.$message.error("抱歉，您还没有此功能的操作权限。");
      }
    },
    closeDiagnosticModal() {
      this.diagnosticVisible = false;
      this.dataForm = {
        problem: "",
        method: "",
      };
    },
    submitDiagnostic() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.isSubmit = true;
          let suggestionType = 1;
          if (this.diagnosticType == "live") {
            suggestionType = 1;
          } else {
            suggestionType = 2;
          }
          let params = {
            dealerId: this.dealer_id,
            effectStartTime: this.form.startTime + " 00:00:00",
            effectEndTime: this.form.endTime + " 23:59:59",
            suggestionType: suggestionType,
            question: this.dataForm.problem,
            suggestion: this.dataForm.method,
          };
          Api.saveSuggestion(params).then((res) => {
            if (res.code == 200) {
              this.closeDiagnosticModal();
              this.getLabelSuggestion();
            } else {
              this.$message.error("提交数据失败");
            }
            this.isSubmit = false;
          });
        }
      });
    },
    onSearchTag(data) {

    },
    onChangeTabs() {
      switch (this.tabActive) {
        case "0":
          this.getLabelDiagnose();
          break;
        case "1":
          // this.getLabelSuggestion();
          this.$nextTick(() => {
            this.$refs.problemPage.getList();
          })
          break;

      }
    },
    onEditTag(type) {
      if (this.authority) {
        switch (type) {
          case "add":
            this.drawerTitle = "添加标签";
            break;
          case "edit":
            this.drawerTitle = "编辑标签";
            break;
        }
        this.drawerVisible = true;
        this.$refs.DealerDrawer.getLabelDisplay();
      } else {
        this.$message.error("抱歉，您还没有此功能的操作权限。");
      }
    },
    onCloseDrawer(data) {
      this.drawerVisible = data;
    },
    onAddTag(data) {
      // console.log(data);
      Api.saveDiagnoseLabel(data).then(res => {
        if (res.code == 200) {
          this.drawerVisible = false;
          this.$message.success("操作成功");
          this.getLabelDiagnose();
        } else {
          this.$message.error("操作失败");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.search-box {
  margin-bottom: 30px;

  .picker-wrap {
    display: flex;
    align-items: center;
    height: 40px;
  }

  .picker-box {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
    width: 350px;

    .picker {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 12px;
      width: 100%;
      height: 32px;
      line-height: 32px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }

    .calendar {
      color: #999;
    }

    /deep/ .ant-calendar-picker-input {
      opacity: 0;
    }
  }
}

.flex-end {
  text-align: right;
}

.info-box {
  margin-top: 20px;
}

.content-box {
  .content {
    margin: 40px 0;
  }

  .main-title {
    position: relative;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 30px;
      background-color: #1890ff;
      border-radius: 2px;
    }
  }

  .title {
    margin-bottom: 20px;
  }

  .table {
    margin: 20px 0 20px 20px;
    display: flex;
    flex-wrap: wrap;

    .row {
      width: 50%;
      margin-top: -1px;
      border: 1px solid #ccc;
      line-height: 0;
    }

    .col {
      width: 70%;
      display: inline-block;
      height: 42px;
      line-height: 42px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;

      .text {
        padding-left: 8px;
      }

      &:nth-child(2n-1) {
        width: 30%;
        background-color: #eee;
        text-align: center;
        border-right: 1px solid #ccc;
      }

      &:nth-child(2n) {
        display: inline-block;
      }

      & + .col {
        //border-left: 1px solid #ccc;
      }
    }
  }


  .table1 {
    margin: 20px 0 20px 20px;

    .row {
      margin-top: -1px;
      border: 1px solid #ccc;
      display: flex;
    }

    .col {
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      padding: 10px;

      &:nth-child(2n-1) {
        width: 20%;
        background-color: #eee;
        border-right: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(2n) {
        display: inline-block;
        height: 100%;
        white-space: pre-line
      }

      & + .col {
        //border-left: 1px solid #ccc;
      }
    }
  }

  .no-result {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
  }
}

.content-box-NOlist {
  display: flex;
  justify-content: center;
  padding: 30px;

  .content {
    text-align: center;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #666;

  .icon {
    font-size: 30px;
    color: #fff;
  }
}
</style>
