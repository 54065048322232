<template>
  <div>
    <a-form-model ref="searchForm" :model="searchForm" class="search-form" v-bind="layout" v-if="type == 3">
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="统计平台">
            <a-select v-model="searchForm.platform_type">
              <a-select-option :value="1">抖音</a-select-option>
              <a-select-option :value="2">快手</a-select-option>
              <a-select-option :value="3">懂车帝</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="统计时间">
            <a-range-picker
                :placeholder="['开始时间', '结束时间']"
                :allowClear="false"
                show-time
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate"
                style="width:100%"
                v-model="searchForm.time"
            ></a-range-picker>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <div class="flex-wrap">
            <a-button class="ml-10p" type="primary" @click="searchClick">搜索</a-button>
            <a-button class="ml-10p" type="dashed" @click="resetSearch">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="isLoading"
        class="mt-40p"
    >
      <div slot="liveInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>直播平台：</span></div>
          <div>{{ record.platform_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>直播名称：</span></div>
          <a @click="() =>showVideoModal(record)">{{ record.live_title }}</a>
        </div>
        <div class="row-box box-1">
          <div><span>直播昵称：</span></div>
          <div>{{ record.author_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>开播时间：</span></div>
          <div>{{ record.publish_time || "--" }}</div>
        </div>
      </div>
      <div slot="template_state" slot-scope="text, record">
        <span>{{ record.template_state == 0 ? "否" : record.template_state == 1 ? "是" : "-" }}</span>
      </div>
      <div slot="p2_oauth_status" slot-scope="text, record">
        <span>{{ record.p2_oauth_status == 1 ? "已授权" : "-" }}</span>
      </div>
      <div slot="p1_oauth_status" slot-scope="text, record">
        <span>{{ record.p1_oauth_status == 1 ? "已授权" : "-" }}</span>
      </div>
    </a-table>

    <div v-if="type == 3">
      <p class="title_tow">最近10次短视频数据</p>
      <a-table
          :columns="columns_ten"
          :data-source="list_ten"
          :pagination="false"
          :rowKey="(record, index) => index"
          :loading="loading"
          class="mt-40p"
      >
        <div slot="template_info" slot-scope="text, record">
          <div class="row-box box-1">
            <div><span>发布平台：</span></div>
            <div>{{ record.platform_name || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>发布帐号：</span></div>
            <div>{{ record.author_name || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>发布时间：</span></div>
            <div>{{ record.source_ctime || "--" }}</div>
          </div>
        </div>
        <div slot="aweme_title" slot-scope="text, record">
          <a @click="() =>showVideoModal(record)">{{ record.aweme_title }}</a>
          <!--<a :href="record.aweme_url" target="_blank">{{ record.aweme_title }}</a>-->
        </div>
        <div slot="play_total_count_completion_rate" slot-scope="text, record">
          <span>
            {{ record.play_total_count_completion_rate ? record.play_total_count_completion_rate + "%" : "-" }}
          </span>
        </div>
      </a-table>
    </div>

    <!--视频播放-->
    <video-modal
        :preview-src="preview_src"
        :visible="preview_visible"
        @cancel="handlePreviewCancel"
    />
  </div>
</template>

<script>
import moment from "moment";
import videoModal from "@/components/videoModal/index";
import https from "@/api/kpi.js";


export default {
  name: "dealer_list",
  data() {
    return {
      preview_src: "",
      preview_visible: false,
      loading: false,
      searchForm: {
        time: [],
        start_time: "",
        end_time: ""
      },
      layout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16}
      },
      columns_ten: [
        {
          dataIndex: "template_info",
          scopedSlots: {customRender: "template_info"},
          title: "发布信息"
        },
        {
          dataIndex: "template_name",
          title: "模板名称"
        },
        {
          dataIndex: "aweme_title",
          scopedSlots: {customRender: "aweme_title"},
          title: "视频名称"
        },
        {
          dataIndex: "play_volume",
          title: "播放量"
        },
        {
          title: '增粉量',
          dataIndex: 'incr_fans_total'
        },
        {
          dataIndex: "digg_count",
          title: "点赞量"
        },
        {
          dataIndex: "share_count",
          title: "分享量"
        },
        {
          dataIndex: "comment_count",
          title: "评论量"
        },
        {
          dataIndex: "play_total_count_completion_rate",
          scopedSlots: {customRender: "play_total_count_completion_rate"},
          title: "完播率"
        },
      ],
      list_ten: []
    };
  },
  components: {
    videoModal
  },
  props: {
    type: {type: Number},
    list: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => [
        {
          dataIndex: "",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "accountInfo"},
          title: "风火轮帐号信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "sectionsInfo"},
          title: "课程学习进度"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "medalInfo"},
          title: "勋章获取情况"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "awardedInfo"},
          title: "认证情况"
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {customRender: "operation"},
          width: 150
        }
      ]
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    dealerId: {type: String}
  },
  mounted() {
    this.$set(this.searchForm, "time", [moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00", moment().format("YYYY-MM-DD") + " 23:59:59"]);
    this.$set(this.searchForm, "end_time", moment().format("YYYY-MM-DD") + " 23:59:59");
    this.$set(this.searchForm, "start_time", moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00");
    if(this.type == 3) {
      this.getAwemeListTen();
    }
  },
  methods: {
    getAwemeListTen() {
      this.loading = true;
      let params = {
        dealer_id: this.dealerId,
        // dealer_id: "1314afanti4",
      };
      https.getAwemeListTen(params).then(res => {
        this.loading = false;
        if(res.code == 200) {
          this.list_ten = res.data || [];
        } else {
          this.list_ten = [];
          this.$message.error(res.message || "获取最近10次视频数据失败");
        }
      });
    },
    // 搜索
    searchClick() {
      // console.log("22");
      this.$emit("searchTabList", this.searchForm, this.type);
    },
    // 重置选择条件
    resetSearch() {
      this.searchForm = {};
      this.$set(this.searchForm, "time", [moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00", moment().format("YYYY-MM-DD") + " 23:59:59"]);
      this.$set(this.searchForm, "end_time", moment().format("YYYY-MM-DD") + " 23:59:59");
      this.$set(this.searchForm, "start_time", moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00");
      this.$emit("searchTabList", this.searchForm, this.type);
    },
    //日期选择框
    onChangeDate(dates) {
      let start_time = "";
      let end_time = "";
      if(dates[0]) {
        start_time = this.$moment(dates[0]._d).format("YYYY-MM-DD") + " 00:00:00";
        end_time = this.$moment(dates[1]._d).format("YYYY-MM-DD") + " 23:59:59";
      }
      this.searchForm.end_time = end_time;
      this.searchForm.start_time = start_time;
    },
    showVideoModal(record) {
      // console.log(record);
      this.preview_visible = true;
      let url = record.aweme_url ? record.aweme_url : record.play_url ? record.play_url : null;
      // let url = str.replace(/^http/, "https");
      if(!url) {
        this.$message.error("暂无播放链接");
        return false;
      }
      this.preview_src = url;
    },
    handlePreviewCancel() {
      this.preview_visible = false;
      this.preview_src = "";
    },
  }
};
</script>

<style scoped lang="less">
.title_tow {
  font-size: 14px;
  font-weight: bold;
  padding: 20px 0;
}

.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  & > div:last-child {
    flex: 1;
    min-width: 60px;
  }
}

.box-1 {
  & > div:first-child {
    width: 100px;
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 30px 30px 0px;
}
</style>
