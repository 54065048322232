<template>
  <div>
    <div class="search-box">
      <a-form-model class="panel panel-default panel-search" :model="form" layout="inline">
        <a-form-model-item label="统计范围">
          <div class="picker-wrap">
            <a-radio-group button-style="solid" v-model="ctimeType" @change="handleCtimeTypeChange">
              <a-radio-button
                  v-for="item in ctimeTypeList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-radio-button>
            </a-radio-group>
            <div class="picker-box">
              <div class="picker">
                <a-icon type="calendar" class="calendar" v-if="pickerTime"/>
                {{ pickerTime || "请选择时间" }}
              </div>
              <a-week-picker
                  v-model="weekTime"
                  format="YYYY-MM-DD"
                  placeholder="请选择时间"
                  @change="onChangeTime"
                  style="width: 100%;"
                  :allowClear="false"
              />
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="table">
      <problemTableList ref="problemTableList" :time="data_time"/>
    </div>
  </div>
</template>

<script>
import problemTableList from "./problem_table_list";

export default {
  name: "problem_page",
  components: {problemTableList},
  data() {
    return {
      form: {
        startTime: this.$moment().startOf("week").format("YYYY-MM-DD"),
        endTime: this.$moment().endOf("week").format("YYYY-MM-DD"),
      },
      pickerTime: `本周 | ${ this.$moment().startOf("week").format("YYYY-MM-DD") } 至 ${ this.$moment().endOf("week").format("YYYY-MM-DD") }`,
      weekTime: this.$moment(),
      ctimeType: "0", // 1, 2
      ctimeTypeList: [
        {label: "本周", value: "0"},
        {label: "上周", value: "-7"},
      ],
      data_time: {
        startTime: this.$moment().startOf("week").format("YYYY-MM-DD"),
        endTime: this.$moment().endOf("week").format("YYYY-MM-DD"),
        week: "本周",
      }
    };
  },
  methods: {
    getList() {
      this.$refs.problemTableList.getPdmsRecordList();
    },
    handleCtimeTypeChange() {
      let time = this.$moment();
      switch(this.ctimeType) {
        case "0":
          this.onChangeTime(time);
          break;
        case "-7":
          this.onChangeTime(this.$moment(time.startOf("week").subtract("week", 1).format("YYYY-MM-DD")));
          break;

      }
    },
    onChangeTime(date) {
      if(date) {
        let week = "0";
        let startTime = date.startOf("week").format("YYYY-MM-DD");

        if(startTime == this.$moment().startOf("week").format("YYYY-MM-DD")) {
          week = "0";
          this.ctimeType = "0";
        } else if(startTime == this.$moment().startOf("week").subtract("week", 1).format("YYYY-MM-DD")) {
          week = "-7";
          this.ctimeType = "-7";
        } else {
          week = date.week();
          this.ctimeType = "";
        }
        this.form.startTime = startTime;
        this.form.endTime = date.endOf("week").format("YYYY-MM-DD");
        this.pickerTime = `${ this.formatWeek(week) } | ${ startTime } 至 ${ this.form.endTime }`;
        this.weekTime = this.form.endTime;
        this.data_time = {
          startTime: this.form.startTime,
          endTime: this.form.endTime,
          week: this.formatWeek(week),
        };
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
        this.pickerTime = "";
        this.weekTime = "";
        this.data_time = {
          startTime: "",
          endTime: "",
          week: "",
        };
      }
    },
    formatWeek(week) {
      let txt = "";
      switch(week) {
        case "0":
          txt = "本周";
          break;
        case "-7":
          txt = "上周";
          break;
        default:
          txt = `第${ week }周`;
          break;
      }
      return txt;

    },
  }
};
</script>

<style scoped lang="less">
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .picker-wrap {
    display: flex;
    align-items: center;
    height: 40px;
  }

  .picker-box {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
    width: 350px;

    .picker {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 12px;
      width: 100%;
      height: 32px;
      line-height: 32px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }

    .calendar {
      color: #999;
    }

    /deep/ .ant-calendar-picker-input {
      opacity: 0;
    }
  }
}

.info-box {
  padding: 20px 0;
}
</style>
